import { HmacSHA256 } from "crypto-js"
import { useEffect } from "react"

import { useAuthState } from "@costory/front/queries/auth"

export default function Gtm() {
  const auth = useAuthState()
  const userIdentifier = auth.user?.email
  const userHash = userIdentifier
    ? HmacSHA256(
        userIdentifier,
        import.meta.env.VITE_INTERCOM_SECRET,
      ).toString()
    : ""

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "page_view",
      page: window.location.href,
      env: import.meta.env.VITE_ENVIRONMENT,
      user: `${auth.user?.firstName} ${auth.user?.lastName}`,
      org: auth.user?.currentOrg?.slug ?? "no-org",
      emailDomain: auth.user?.email.split("@")[1],
      email: auth.user?.email,
      userHash,
    })
  }, [auth, userHash])

  return null
}
