import { useMutation, useQuery } from "@tanstack/react-query"

import { queryClient } from "@costory/front/lib/queryClient"
import {
  createBudget,
  deleteBudget,
  duplicateBudget,
  getBudget,
  getBudgetCreationFormData,
  getBudgetsList,
  getCSVExport,
  updateBudget,
} from "@costory/front/services/budgets"

export const useBudgetsListQuery = () => {
  return useQuery({
    queryKey: ["budgets"],
    queryFn: getBudgetsList,
  })
}

export const useGetBudgetQuery = (id: string) => {
  return useQuery({
    queryKey: ["budgets", id],
    queryFn: () => getBudget(id),
  })
}

export const useDeleteBudgetMutation = () => {
  return useMutation({
    mutationFn: deleteBudget,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["budgets"] })
    },
  })
}

export const useDuplicateBudgetMutation = () => {
  return useMutation({
    mutationFn: duplicateBudget,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["budgets"] })
    },
  })
}

export const useBudgetFormDataQuery = () => {
  return useQuery({
    queryKey: ["budgets", "new"],
    queryFn: getBudgetCreationFormData,
  })
}

export const useCreateBudgetMutation = () => {
  return useMutation({
    mutationFn: createBudget,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["budgets"] })
    },
  })
}

export const useUpdateBudgetMutation = () => {
  return useMutation({
    mutationFn: updateBudget,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["budgets"] })
    },
  })
}

export const useGetBudgetCSVExportQuery = (budgetId: string) => {
  return useQuery({
    queryKey: ["budgets", budgetId, "csv"],
    queryFn: () => getCSVExport(budgetId),
    enabled: false,
  })
}
